import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["menuOpen"]
  static targets = ["menu"]
  static values = {
    menuOpen: { type: Boolean, default: false },
    animating: { type: Boolean, default: false },
  }

  toggleMenu() {
    if (this.animatingValue) return
    if (!this.hasMenuTarget) return
    this.menuOpenValue = !this.menuOpenValue
  }

  menuOpenValueChanged() {
    this.menuTarget.classList.toggle(this.menuOpenClass, this.menuOpenValue)
  }

  onTransitionstart() {
    this.animatingValue = true
  }

  onTransitionend(event) {
    if (event.propertyName != "opacity") return
    if (this.menuOpenValue) {
      if (event.srcElement != this.menuTarget) this.dispatch("animationend")
      // this.animatingValue = event.srcElement === this.menuTarget
    } else {
      if (event.srcElement === this.menuTarget) this.dispatch("animationend")
      // this.animatingValue = event.srcElement != this.menuTarget
    }
  }

  onAnimationend() {
    this.animatingValue = false
  }

  // onMenuTurboClick(event) {
  //   event.preventDefault()
  //   this.toggleMenu()
  //   Turbo.visit(event.target.getAttribute("href"))
  // }

  async onMenuTurboBeforeVisit(event) {
    if (!this.menuOpenValue) return
    event.preventDefault()
    this.toggleMenu()
    await new Promise((resolve) => {
      const listener = () => {
        this.element.removeEventListener(
          "main-menu-block-component:animationend",
          listener,
        )
        resolve()
      }
      this.element.addEventListener(
        "main-menu-block-component:animationend",
        listener,
      )
    })
    Turbo.visit(event.detail.url)
  }
}
