import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  disable() {
    this.element.classList.add("disable-scroll")
  }

  enable() {
    this.element.classList.remove("disable-scroll")
  }
}
