import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]
  static classes = ["animate", "finish"]

  connect() {
    this.dispatch("connect")
    this.animationStarted = false
  }

  // preventScroll(e) {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   return false
  // }

  animate(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.animationStarted) return false
    this.animationStarted = true
    this.element.classList.add(this.animateClass)
    return false
  }

  finish() {
    this.element.classList.add(this.finishClass)
    document.documentElement.classList.add("curtain-finished")
    this.dispatch("finish")
  }
}
