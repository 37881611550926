import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"
import "flickity/dist/flickity.min.css"

export default class extends Controller {
  static targets = ["slides", "slide", "pagerCurrent", "caption"]

  connect() {
    this.flickity = new Flickity(this.slidesTarget, {
      adaptiveHeight: true,
      cellAlign: "left",
      contain: true,
      draggable: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      on: {
        ready: () => {
          this.updateFooter()
        },
        change: () => {
          this.updateFooter()
        },
      },
    })
  }

  updateFooter() {
    this.pagerCurrentTarget.innerText = this.currentIndex + 1
    this.captionTarget.innerText = this.currentSlideCaption
  }

  previous() {
    this.flickity.previous()
  }

  next() {
    this.flickity.next()
  }

  get currentIndex() {
    if (!this.flickity) return 0
    return this.flickity.selectedIndex
  }

  get currentSlideCaption() {
    if (!this.currentSlide) return ""
    return this.currentSlide.dataset.caption
  }

  get currentSlide() {
    return this.slideTargets.find((slide) => {
      return slide.classList.contains("is-selected")
    })
  }
}
