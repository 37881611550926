import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    useClickOutside(this)
  }

  focusInput() {
    if (this.element.open) {
      this.inputTarget.focus()
    }
  }

  clickOutside() {
    this.element.open = false
  }
}
